<template>
  <v-card>
    <v-card-title class="toptitle">
      <span class="text-h3">
        {{ type }} Group
      </span>
    </v-card-title>
    <v-card class="addNewCard">
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-row dense>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  v-model="vehicle.group_name"
                  dense
                  type="text"
                  maxlength="30"
                  :label="$t('Group Name')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-autocomplete
                  v-model="vehicle.parent"
                  class="text-capitalize"
                  dense
                  :loading="getIsLoadingGroup"
                  :items="groupsList"
                  item-text="group_name"
                  item-value="id"
                  :label="$t('Select Parent Group')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-autocomplete
                  v-model="vehicle.md_make_id"
                  clearable
                  dense
                  :items="authUser.isAdmin
                    ? vehicle.tenant_id
                      ? mdMakesList
                      : []
                    : mdMakesList
                  "
                  :loading="isLoadingMdMakes"
                  item-value="id"
                  item-text="name"
                  :label="$t('ev_make_*')"
                  @change="onMakeChange"
                />
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-select
                  v-model="vehicle.md_model_id"
                  dense
                  clearable
                  :items="vehicle.md_make_id ? mdModelsList : []"
                  :loading="isLoadingMdModels"
                  item-value="id"
                  item-text="name"
                  :label="$t('ev_model_*')"
                  @change="onModelChange"
                />
              </v-col>
              <!-- Select Vehicle Section -->
              <v-col
                cols="12"
                class="select-vehicle-section"
              >
                <div class="selectAllWrap">
                  <div class="section-heading">
                    Select Vehicles
                  </div>
                  <v-btn
                    v-if="vehicleList.length > 0"
                    small
                    class="ml-2"
                    color="primary"
                    @click="toggleSelectAll"
                  >
                    {{ areAllSelected ? 'Deselect All' : 'Select All' }}
                  </v-btn>
                </div>
                <v-row
                  v-if="vehicleList.length == 0"
                  class="noDataWrap"
                >
                  No Data Available
                </v-row>

                <v-row
                  dense
                  class="allVehicleRow"
                >
                  <!-- Show loading spinner if vehicleLoading is true -->
                  <template v-if="vehicleLoading">
                    <v-col
                      cols="12"
                      class="loading-wrap"
                    >
                      <v-skeleton-loader
                        type="list-item-two-line"
                        style="flex: 1"
                      />
                    </v-col>
                  </template>

                  <!-- Show vehicle list if vehicleLoading is false -->
                  <template v-else>
                    <v-col
                      v-for="v in vehicleList"
                      :key="v.id"
                      cols="12"
                      sm="4"
                    >
                      <v-checkbox
                        v-model="selectedVehicles"
                        :value="v.id"
                        :label="v.vehicle_name"
                      />
                    </v-col>
                  </template>
                </v-row>

                <!--
                <v-row v-else dense class="allVehicleRow">
                  <v-col v-for="v in vehicleList" :key="v.id" cols="12" sm="4">
                    <v-checkbox v-model="selectedVehicles" :value="v.id" :label="v.vehicle_name" />
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red darken-1"
        text
        @click="closeForm()"
      >
        Close
      </v-btn>

      <v-btn
        color="primary"
        text
        :loading="isLoadingSave"
        @click="createGroup"
      >
        {{ type }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {

  },
  mixins: [validationRules],
  props: {
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicle: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: [String],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isLoadingSave: false,
      dialog: false,
      selectedVehicles: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      vehicleLoading: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
      getIsLoadingGroup: "vehicles/getIsLoadingGroup",
      groupsList: "vehicles/getGroupsList",
      mdModelsList: "mdModels/activelistMdModels",

    }),

    areAllSelected() {
      return this.selectedVehicles.length === this.vehicleList.length;
    },
  },

  async mounted() {
    let params = {
      tenant_id:  this.authUser.tenant_id,
      product: this.authUser.user_nature
    };
    await this.$store.dispatch("vehicles/vehicleDropDown",params );

    await this.$store.dispatch("mdMakes/list", params);
    if(this.type=='Update'){
      params.vgroup_id = this.vehicle.id;
    }
    this.$store.dispatch("vehicles/groupDropDown", params);
    if (Array.isArray(this.vehicle.vehicles)) {
      this.selectedVehicles = this.vehicle.vehicles.map(v => v.id); // Or Number(v.id) if needed
    }
  },
  methods: {
    toggleSelectAll() {
      if (this.areAllSelected) {
        // Deselect all
        this.selectedVehicles = [];
      } else {
        // Select all
        this.selectedVehicles = this.vehicleList.map((v) => v.id);
      }
    },
    closeForm() {
      this.$emit("onclose");
    },

    async createGroup() {

      const isValid = this.$refs.form?.validate();
      if (!isValid) {
        return;
      }
      if (this.selectedVehicles.length == 0) {
        this.$store.dispatch("alerts/error", "Select Vehicle");
        return;
      }
      let params = {}
      params.add_vehicles = this.selectedVehicles
      params.tenant_id = this.options.tenant_id
      params.group_name = this.vehicle.group_name
      params.parent = (typeof this.vehicle.parent === 'object') ? this.vehicle.parent.id : this.vehicle.parent
      let url = "vehicles/createVechicleGroup"
      let msg = this.$t("created_successfully")
      if (this.type == 'Update') {
        params.id = this.vehicle.id
        url = "vehicles/updateVechicleGroup"
        msg = this.$t("updated_successfully")
      }
      await this.$store
        .dispatch(url, params)
        .then(() => {
          this.$store.dispatch(
            "alerts/success", msg
          );
          this.$emit("change");
        }).catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    onMakeChange(v) {
      delete this.vehicle.md_model_id;
      let params = {
        tenant_id: this.options.tenant_id,
        product: this.options.product,
      }
      if (v) {
        this.$store.dispatch("mdModels/list", {
          md_make_id: v ?? false,
        });
        params.md_make_id = this.vehicle.md_make_id;
      }

      this.$store.dispatch("vehicles/vehicleDropDown", { ...params });

    },
    onModelChange() {
      this.$store.dispatch("vehicles/vehicleDropDown", {
        product: this.options.product,
        tenant_id: this.options.tenant_id,
        md_make_id: this.vehicle.md_make_id,
        md_model_id: this.vehicle.md_model_id
      });
    },


  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.searchBoxwithBtn
  display: flex
  flex-wrap: wrap
  align-items: center
  :deep .v-input
    width: 70%
    margin-right: 1.75rem
  :deep button
    margin: 0 !important
.toptitle
  #backBtn
    padding: 0 !important
    position: absolute
    left: 27px
    background: #eee
    display: flex
    align-items: center
    justify-content: center
    align-items: center
    margin: 0 !important
    :deep .v-btn__content
      .v-icon
        position: relative
        top: unset
        left: unset
        right: unset
        opacity: 1
        font-size: 21px
        color: #333
        height: 29px !important
.v-application
  .bodTop
      margin-top: 1rem !important
      display: block
      padding-top: 1rem
  .addNewCard
    box-shadow: 0 0 0 !important
    border: 0 !important
    .container
      padding-top: 0
      padding-bottom: 0
      .custom-h3
        font-size: 1.25rem !important
        color: #333
        font-family: $poppinMed !important
        margin-bottom: 0.75rem !important
        display: inline-block
.userAssignWrap
    display: flex
    margin: 0
    padding: 12px
    flex-wrap: wrap
    .row
      margin: 0 !important
      width: 100% !important


.select-vehicle-section
  margin-top: 1rem


.section-heading
  font-size: 1.25rem
  font-weight: bold
  margin-bottom: 0.5rem
  color: #333


.v-checkbox
  margin-bottom: 0.5rem
.select-vehicle-section
  :deep .v-messages
      display: none
      height: 0
  :deep .v-input--selection-controls
      margin-top: 0 !important
  :deep .v-input__slot
      margin-bottom: 0 !important
  .allVehicleRow
    :deep .v-label
      font-size: 0.9375rem !important
      color: #333
      font-family: $poppinReg !important

.selectAllWrap
  justify-content: space-between
  display: flex
  .v-btn
    margin:0 !important
</style>